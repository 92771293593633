<template>
  <!-- 添加发票抬头/换开发票抬头 -->
  <div class="invoiceModal">
    <a-modal
      :dialog-style="{ top: '180px' }"
      :width="606"
      :visible="ifanalog"
      @cancel="handleCancel"
      :closable="true"
    >
      <template #footer></template>
      <div class="center">
        <div class="info">
          <div class="infotop">
            <span>{{ opendata.name }}</span>
          </div>
          <div class="infocentent">
            <div class="imgtxt">
              <img src="@/assets/image/study/Group5737.png" alt="" />
              <span>及格分数：{{ opendata.passingScore }}分</span>
              <div class="borleft">
                <img src="@/assets/image/study/Group573.png" alt="" />
                <span>时长：{{ opendata.time }}分钟</span>
              </div>
            </div>
            <p>注：考试过程中退出当前页面或关闭华夏云课堂均按交卷处理</p>
          </div>
        </div>
        <div class="box_button" >
          <div class="all-btn-small" @click="goExam()">开始考试</div>
        </div>
        <div class="bottom" v-if="infoList.length">
          <div class="title">
            <img src="@/assets/image/study/Vecto2.png" alt="" />历史答题记录
          </div>
          <div class="content" v-for="(item, index) in infoList" :key="index">
            <span class="span1"
              >成绩：<a>{{ item.examScore }}</a
              >分</span
            >
            <div class="grey"></div>
            <div class="all-btn-small" @click="onConfirm()">查看试卷</div>
            <span class="span2">时间：{{ item.examDate }}</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    "ifanalog", // 控制弹窗显示/隐藏  必填
    "invoiceType", //发票类型 1添加发票 2换开发票
    "configId", //课程id
    "opendata", //渲染数据
  ],
  // 数据对象
  data() {
    return {
      infoList: [],
      infoData: {},
      current: 2,
    };
  },
  // 事件处理器
  methods: {
    // 去考试
    goExam() {
      this.$router.push(
        "/knowledgeMall/examSystem?paperId=" +
          this.$AES.encode_data(this.opendata.paperId + "") +
          "&configId=" +
          this.$AES.encode_data(this.opendata.configId + "")
      );
    },
    // 获取历史记录
    getInfo(e) {
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/user/list",
        params: {
          configId: e,
          userId: this.$store.state.userInfo.userId, //用户id
          current: 1,
          size: 1,
        },
      }).then((res) => {
        this.loadingInfo = false;
        if (res.code == 200 && res.success) {
          // console.log(res.data.records)
          this.infoList = res.data.records;
        }
      });
    },
    // 保存
    onConfirm(e) {
      this.$router.push(
        "/knowledgeMall/examSystem?userpPaperId=" +
          this.$AES.encode_data(this.infoList[0].userpPaperId + "") +
          "&examScore=" +
          this.$AES.encode_data(this.infoList[0].examScore + "")
      );
    },
    handleCancel(e) {
      this.$emit("onana");
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    configId(newval) {
      this.getInfo(this.configId);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.ant-modal-footer {
  display: none;
}
/deep/.ant-modal-body {
  padding: 0px !important;
}
.infotop {
  margin-bottom: 38px;
  margin-top: 32px;
  text-align: center;
  span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
  }
}
.center {
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding-bottom: 34px;
  border-radius: 8px 8px 8px 8px;
  .info {
    width: 100%;
    padding: 0 20px;

    .infocentent {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      .imgtxt {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 14px;
          margin-right: 3px;
        }
        span {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .a1 {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #15b7dd;
        }
        .a2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #15b7dd;
        }
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-top: 30px;
        color: #666666;
        line-height: 16px;
      }
      .borleft {
        border-left: 1px solid rgba(0, 0, 0, 0.5);
        padding-left: 18px;
        margin-left: 18px;
        font-size: 12px;
        display: flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.bottom {
  width: 526px;
  border-top: 1px solid #f2f2f2;
  margin: 0 auto;
  margin-top: 40px;
  padding-top: 28px;
  overflow: hidden;
  img {
    width: 18px;
    margin-right: 4px;
  }
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-top: 23px;
    .span1 {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }
    .grey {
      width: 1px;
      height: 20px;
      background: #d9d9d9;
    }
    .span2 {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 18px;
    }
  }
}
.box_button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 0 40px;
  .all-btn-blue,
  .all-btn-small {
    font-size: 16px;
  }
}
.pick {
  span {
    color: #ffffff !important;
  }
  background: #1cb9de;
}
</style>
