<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/study/study"
          >学习中心</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">模拟考试列表</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="center">
      <div class="mockList">
        <div class="listitem" v-for="(item, index) in MockList" :key="index">
          <div class="itemdiv">
            <div class="ititmleft">
              <img :src="item.photo" alt="" />
            </div>
            <div class="ititmright">
              <span class="title">{{ item.mockName }}</span>
              <span class="label"
                >时长：{{ item.time }}分钟 |
                {{ item.isExam ? item.examNum + "次答题" : "无限制" }}</span
              >
              <a-button
                @click="onana(item)"
                class="all-btn-small"
                type="primary"
              >
                去考试
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 卷子 -->
    <analog
      :ifanalog="ifanalog"
      :configId="configId"
      :opendata="opendata"
      @onana="closeana"
    ></analog>
  </div>
</template>

<script>
import analog from "../../components/study/analog.vue"; // 考试记录

export default {
  // 可用组件的哈希表
  components: { analog },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 历史记录弹窗
      ifHistory: false, //判断
      // 考试记录弹窗
      ifanalog: false, //判断
      //卷子数据
      opendata: {
        name: null,//
        passingScore: null,//及格分数
        paperId: null,//
        configId: null,//
        duration: null, //时长
      },

      MockList: [],

      // 考试情况
      examCaseList: [],
      // 考试成绩

      configId: null, //模拟考试id
    };
  },
  methods: {
    // 卷子 - 打开
    onana(e) {
      this.opendata.name = e.mockName;
      this.opendata.time = e.time;
      this.opendata.paperId = e.paperId;
      this.opendata.passingScore = e.passingScore;
      this.opendata.configId = e.configId;
      this.configId = e.configId;
      this.ifanalog = true;
    },
    // 卷子 - 关闭
    closeana() {
      this.ifanalog = false;
    },
    // 获取模拟考试
    getMock() {
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/userCourse/mockExam",
        method: "get",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          res.data.forEach((item) => {
            let a = item.examTime.substring(0, 2);
            let b = item.examTime.substring(3, 5);
            let time = a * 60 + b * 1;
            item.time = time;
          });
          this.MockList = res.data;
        }
      });
    },
  },
  mounted() {
    this.getMock();
  },
};
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .mockList {
    position: relative;
    width: 100%;
    min-height: 400px;
    padding: 30px;
    font-family: PingFangMedium;
    margin-top: 22px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    .listitem {
      width: 41%;
      margin-bottom: 24px;
      .itemdiv {
        width: 433px;
        height: 145px;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(93, 131, 167, 0.1);
        border-radius: 16px 16px 16px 16px;
        padding: 12px 16px;
        display: flex;
        .ititmleft {
          width: 120px;
          height: 120px;
          min-width: 120px;
          min-height: 120px;
          box-shadow: 0px 5px 5px 0px rgba(93, 131, 167, 0.1);
          border-radius: 5px 5px 8px 5px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            object-fit: cover;
          }
        }
        .ititmright {
          margin-left: 28px;
          .title {
            min-height: 46px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            display: block;
            line-height: 23px;
          }
          .label {
            font-size: 12px;
            font-weight: 400;
            display: block;
            color: #666666;
            line-height: 21px;
          }
          .all-btn-small {
            font-size: 14px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
/*  小号按钮  */
.all-btn-small {
  width: 112px;
  height: 34px;
  line-height: 34px;
  border-radius: 40px;
}
</style>